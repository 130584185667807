import React from 'react';
import Layout from '../components/Layout';
import { SEO } from '../components/seo';
import Form from '../components/FormTest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faPhone, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

const rowPadding = {
  padding: "100px 0"
}

export default function ContactUs() {

  return (
    <Layout>
      <section style={{marginTop: '74px'}}>
        <div className='w-100 contact-us-bg'>
          <div className='container'>
            <div className='row align-items-center row-padding' style={rowPadding}>
              <div className='col-md-6 text-white'>
                <h1 className='display-4'>Contact Us</h1>
                <p className='fs-5'>Get in touch with us, we’re happy to help.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className="row gy-3 text-dark justify-content-md-center" style={rowPadding}>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card shadow">
                <div className="card-body p-4 d-flex align-items-start flex-column" style={{ height: '287px' }}>
                  <FontAwesomeIcon icon={faMailBulk} className='text-primary' style={{ fontSize: '24px', marginBottom : '20px' }} />
                  <h6 className="card-title">Mailing Address</h6>
                  <p className="card-text">We'll be ready to answer your questions.</p>
                  <address className='m-0'>
                    <p className='fw-bold'>
                      Sales Team - <a href="mailto:office@googolweb.com" className="fw-bold">office@googolweb.com</a>
                    </p>
                    <p className='fw-bold'>
                      Support - <a href="mailto:support@googolweb.com" className="fw-bold">support@googolweb.com</a>
                    </p>
                  </address>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card shadow">
                <div className="card-body p-4 d-flex align-items-start flex-column" style={{ height: '287px' }}>
                  <FontAwesomeIcon icon={faPhone} className='text-primary' style={{ fontSize: '24px', marginBottom : '20px' }} />
                  <h6 className="card-title">Call Us</h6>
                  <p>We'll be ready to answer your calls from 8:00 AM to 5:00 PM. Our line is always open.</p>
                  <address className='m-0'>
                    <p className='fw-bold'>New Zealand - <a href="tel:+64220337675" className="fw-bold">+64 (22 033 7675)</a></p>
                  </address>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card shadow">
                <div className="card-body p-4 d-flex align-items-start flex-column" style={{ height: '287px' }}>
                  <FontAwesomeIcon icon={faMapMarkedAlt} className='text-primary' style={{ fontSize: '24px', marginBottom : '20px' }} />
                  <h6 className="card-title">Office Address</h6>
                  <p>Our office location you can visit us.</p>

                  <p>59 Ridge Street, Otumoetai, Tauranga 3110, NZ</p>
                  
                  <a href="https://www.google.com.ph/maps/place/59+Ridge+Street,+Ot%C5%ABmoetai,+Tauranga+3110,+New+Zealand/@-37.67398,176.1371383,17z/data=!4m13!1m7!3m6!1s0x6d6ddb9c80eac6e1:0xd33d1a75ef1627c7!2s59+Ridge+Street,+Ot%C5%ABmoetai,+Tauranga+3110,+New+Zealand!3b1!8m2!3d-37.6739843!4d176.139327!3m4!1s0x6d6ddb9c80eac6e1:0xd33d1a75ef1627c7!8m2!3d-37.6739843!4d176.139327%20style=" target="_blank" rel="noreferrer" className="fw-bold">See Directions</a>
                </div>
              </div>
            </div>
          </div>      
        </div>
      </section>

      <section className='w-100 bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-8 mx-auto text-dark'>
              {/* <form className='row gy-3' onSubmit={handleSubmit} style={rowPadding}>
                <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
              </form> */}

              <Form />
              
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => (
  <SEO title="Contact Us" />
)