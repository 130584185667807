import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'

import { useForm } from '../hooks'

const Form = () => {

    const firstnameInput = useRef(null);
    const [response, setResponse] = useState('');
    // const [token, setToken] = useState();

    const validate = (values) => {
        let errors = {};
        let errorMsg = 'This field is required!'

        const emailRegexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        const phoneRegexPattern = new RegExp(/^[0-9\b]+$/);

        if(values.name.trim() === '') {
            errors.name = errorMsg;
        }
        if(values.email.trim() === '') {
            errors.email = errorMsg;
        } else if(!emailRegexPattern.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if(values.phone.trim() === '') {
            errors.phone = errorMsg;
        } else if(!phoneRegexPattern.test(values.phone)) {
            errors.phone = 'This is not a valid phone number!';
        }
        if(values.company.trim() === '') {
            errors.company = errorMsg;
        }

        return errors;
    };

    const addPersonFromForm = async () => {

        console.log(values);

        // fetch('http://localhost:7171/submit/', {
        fetch("https://contact.googolweb.com/submit" , {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "name": values.name,
            "email": values.email,
            "phone": values.phone,
            "company": values.company,
            "message": values.message,
            // "token": token
        })
        })
        .then(res => res.json())
        .then(res => {
            // setLoading(false);
            setResponse(res);
            console.log(res);
        });
    };
    
    const { values, errors, onChange, onSubmit }  = useForm(
        addPersonFromForm, 
        { name: '', email: '', phone: '', company: '', message: '' }, 
        validate 
    );

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57"
            container={{ // optional to render inside custom element
                element: "inline-badge",
                parameters: {
                  badge: 'inline', // optional, default undefined
                }
            }}>
            
            <div className="col">
                <form onSubmit={onSubmit} className='row gy-3 py-5'>
                    <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
                    <div className="col-12 col-md-6">
                        <label htmlFor="name" className='form-label'>Name:</label>
                        <input
                            type="text"
                            id="name"
                            className={`form-control ${errors.name && 'is-invalid'}`}
                            name="name"
                            value={values.name}
                            ref={firstnameInput}
                            onChange={onChange}
                        />
                        {errors.name && (
                            <span className='invalid-feedback'>
                                {errors.name}
                            </span>
                        )}
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="email" className='form-label'>Email:</label>
                        <input
                            type="text"
                            id="email"
                            className={`form-control ${errors.email && 'is-invalid'}`} 
                            name="email"
                            value={values.email}
                            onChange={onChange}
                        />
                        {errors.email && (
                            <span className='invalid-feedback'>
                                {errors.email}
                            </span>
                        )}
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="phone" className='form-label'>Phone:</label>
                        <input
                            type="text"
                            id="phone"
                            className={`form-control ${errors.phone && 'is-invalid'}`} 
                            name="phone"
                            value={values.phone}
                            onChange={onChange}
                        />
                        {errors.phone && (
                            <span className='invalid-feedback'>
                                {errors.phone}
                            </span>
                        )}
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="company" className='form-label'>Company / Organization:</label>
                        <input
                            type="text"
                            id="company"
                            className={`form-control ${errors.company && 'is-invalid'}`} 
                            name="company"
                            value={values.company}
                            onChange={onChange}
                        />
                        {errors.company && (
                            <span className='invalid-feedback'>
                                {errors.company}
                            </span>
                        )}
                    </div>

                    <div className="col-12">
                        <label htmlFor="company" className='form-label'>Mesage:</label>
                        <textarea 
                        id="message" 
                        name='message' 
                        rows="5" 
                        className='form-control' 
                        onChange={onChange} 
                        value={values.message}>
                        </textarea>
                    </div>

                    <div id="inline-badge"></div>

                    <div className='col-12'>
                        <button type="submit" className="btn btn-orange default-padding shadow text-capitalize" style={{ width: '150px' }}> Send </button>
                  </div>
                    {response && (
                        <div className='success-message'>
                            {response}
                        </div>
                    )}
                </form>
            </div>
        </GoogleReCaptchaProvider>
    );
};

export default Form;